<template>
  <page-layout :show-back-button="false">
    <span slot="header-name">{{ $tf('common.users') }}</span>
    <el-button
      slot="header-actions"
      name="create-btn"
      class="header-button"
      circle
      icon="el-icon-plus"
      size="small"
      :disabled="$hasNoPermission('ffsecurity.add_user')"
      @click="$router.push({ path: '/users/create/' })"
    />
    <el-table
      slot="content"
      name="users-table"
      class="users-table table-medium"
      stripe
      :size="$vars.sizes.table || $vars.sizes.common"
      :data="state.items"
      row-key="id"
      @row-click="(item) => $router.push({ path: getItemLink(item) })"
      @sort-change="sortChangeHandler"
    >
      <el-table-column prop="id" :label="$tf('common.id')" width="80" sortable="custom"></el-table-column>
      <el-table-column prop="login" :label="$tf('common.login')" sortable="custom">
        <template slot-scope="{ row }">
          <router-link :to="getItemLink(row)">{{ row.name | getIdByUrl }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="real_name" :label="$tf('common.name')" sortable="custom"></el-table-column>
      <el-table-column prop="groups" :label="$tf('primary | role')" sortable="custom">
        <template slot-scope="{ row }">
          {{ getGroup(row.primary_group) }}
        </template>
      </el-table-column>
      <el-table-column prop="enabled" :label="$tf('common.active')" width="120" align="center" sortable="custom">
        <template slot-scope="{ row }">
          <div @click.stop class="text-center">
            <el-checkbox v-model="row.active" @change="(v) => activeChange(v, row)" />
          </div>
        </template>
      </el-table-column>
    </el-table>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/page/layout';

function ordering(prop, order) {
  return order === 'ascending' ? prop : `-${prop}`;
}

export default {
  name: 'page-users',
  components: {
    PageLayout
  },
  computed: {
    filter() {
      return this.$store.state.users.filter.current;
    },
    state() {
      return this.$store.state.users;
    }
  },
  mounted() {
    this.setPage(0);
    this.$store.dispatch(this.$store.state.groups.Action.Get).then((v) => {
      this.$store.dispatch(this.state.Action.Get);
    });
  },
  watch: {
    'filter.ordering': function (v, p) {
      this.applySort();
    }
  },
  methods: {
    getItemLink(item) {
      return '/users/' + encodeURIComponent(item.id) + '/';
    },
    getGroup(value) {
      let group = this.$store.state.groups.items.find((v) => v.id === value);
      return group ? group.name : value;
    },
    setPage(value) {
      this.state.page = value;
      this.$store.dispatch(this.state.Action.Get);
    },
    firstPage() {
      this.setPage(0);
    },
    prevPage() {
      this.setPage(this.state.page - 1);
    },
    nextPage() {
      this.setPage(this.state.next_page);
    },
    reloadPage() {
      this.$store.dispatch(this.state.Action.Get);
    },
    activeChange(v, item) {
      this.$store.dispatch(this.state.Action.Update, { id: item.id, active: v }).catch((e) => {
        item.active = !v;
      });
      return false;
    },
    applySort() {
      const ordering = this.filter.ordering || 'id',
        order = ordering[0] === '-' ? 'descending' : 'ascending',
        prop = ordering.match(/^-?(.*)/)[1];
      this.$refs.table && this.$refs.table.sort(prop, order);
    },

    sortChangeHandler({ prop, order }) {
      const filterSerialize = {
        login: 'username',
        id: 'id',
        enabled: 'is_active',
        groups: 'primary_group',
        real_name: 'first_name'
      };
      const propForFilter = filterSerialize[prop];
      const filter = { ordering: ordering(propForFilter, order) };
      Object.assign(this.filter, filter);
    }
  }
};
</script>
